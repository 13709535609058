import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Alert,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { nord } from "react-syntax-highlighter/dist/esm/styles/prism";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import RootState from "../../redux/types/rootState";
import { resetError } from "../../redux/slice/errorSlice";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement<unknown> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ErrorHandler: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const error = useSelector((state: RootState) => state.errorState);
  const dispatch = useDispatch();
  const onClose = () => dispatch(resetError());

  const handleCopy = () => {
    navigator.clipboard
      .writeText(JSON.stringify(error, null, 2))
      .then(() => enqueueSnackbar("Error details copied to clipboard!"))
      .catch(() => enqueueSnackbar("Failed to copy error details."));
  };

  return (
    <Dialog
      open={error.hata > -1}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-labelledby="error-dialog-title"
      aria-describedby="error-dialog-description"
      PaperProps={{ style: { position: "relative" } }}
    >
      <DialogTitle id="error-dialog-title">Error</DialogTitle>
      <DialogContent
        style={{ padding: "15px 10px", minWidth: "450px" }}
        dividers
      >
        <Alert
          severity="error"
          style={{
            marginBottom: "10px",
            padding: "5px 0px",
            fontFamily: "DFDS, sans-serif",
          }}
        >
          An error has occurred.
        </Alert>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
              style={{
                lineHeight: "38px",
                // fontFamily: "DFDS-Bold, sans-serif",
                // letterSpacing: "1px",
              }}
            >
              View Details
            </Typography>
            <IconButton
              onClick={handleCopy}
              size="small"
              style={{ marginLeft: "auto" }}
            >
              <ContentCopyIcon />
            </IconButton>
          </AccordionSummary>
          <AccordionDetails>
            <SyntaxHighlighter
              language="json"
              style={nord}
              customStyle={{ fontSize: "12px" }}
            >
              {JSON.stringify(error, null, 2)}
            </SyntaxHighlighter>
          </AccordionDetails>
        </Accordion>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorHandler;
