import { configureStore } from "@reduxjs/toolkit";
import userSlice from "../slice/userSlice";
import errorSlice from "../slice/errorSlice";

const store = configureStore({
  reducer: {
    userState: userSlice,
    errorState: errorSlice,
  },
  devTools: true,
});
export type AppDispatch = typeof store.dispatch;
export default store;
