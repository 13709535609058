import { createSlice } from "@reduxjs/toolkit";
import { ErrorState } from "../types/errorState";

const initialState: ErrorState = {
  ID: "",
  hata: -1,
  hataText: "",
};

export const errorSlice = createSlice({
  name: "hata",
  initialState: initialState,
  reducers: {
    setError: (state, action) => {
      state.ID = action.payload.ID;
      state.hata = action.payload.hata;
      state.hataText = action.payload.hataText;
    },
    resetError: (state) => {
      state.ID = initialState.ID;
      state.hata = initialState.hata;
      state.hataText = initialState.hataText;
    },
  },
});

export const { setError, resetError } = errorSlice.actions;

export default errorSlice.reducer;
