import React, { useState } from "react";
import {
  TextField,
  Button,
  Paper,
  Typography,
  Container,
  Avatar,
  Link,
  IconButton,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { ThemeProvider } from "@mui/material/styles";
import ForgotPassword from "./ForgotPassword";
import { myTheme } from "../../assets/theme";
import { Fingerprint } from "@mui/icons-material";
import { login } from "../../redux/slice/userSlice";
import { UserInfo } from "./ILogin";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store/store";
import RootState from "../../redux/types/rootState";

const Login = () => {
  const [credentials, setCredentials] = useState<UserInfo>({
    username: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const isLoading = useSelector(
    (state: RootState) => state.userState
  )?.isLoading;
  const dispatch = useDispatch<AppDispatch>();
  const handleInputChange = (event: any) => {
    setCredentials({
      ...credentials,
      [event.target.name]: event.target.value,
    });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleBackToLogin = () => {
    setShowForgotPassword(false);
  };

  const handleLogin = async (event: any) => {
    event.preventDefault(); // Prevent the default form submit action
    dispatch(login(credentials));
  };

  return (
    <ThemeProvider theme={myTheme}>
      {showForgotPassword ? (
        <ForgotPassword onBack={handleBackToLogin} />
      ) : (
        <Container
          className="Login-Forget-Content-Container"
          component="main"
          maxWidth="xs"
        >
          <Paper
            elevation={6}
            style={{
              marginTop: "8vh",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "20px",
              backgroundColor: myTheme.palette.background.default,
            }}
          >
            <Container
              className="Login-Header-Container"
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar
                style={{ backgroundColor: myTheme.palette.background.default }}
              >
                <Fingerprint
                  style={{
                    height: "40px",
                    width: "40px",
                    color: myTheme.palette.secondary.main,
                  }}
                />
              </Avatar>
              <Typography
                component="h1"
                variant="h5"
                color={myTheme.palette.secondary.main}
              >
                Sign In
              </Typography>
            </Container>
            <form
              style={{ width: "100%", marginTop: "8px" }}
              noValidate
              onSubmit={handleLogin}
            >
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                autoFocus
                value={credentials.username}
                onChange={handleInputChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                value={credentials.password}
                onChange={handleInputChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={togglePasswordVisibility} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                style={{
                  margin: "24px 0px 16px",
                  background: myTheme.palette.secondary.main,
                }}
              >
                {isLoading ? <CircularProgress size={24} /> : "Sign In"}
              </Button>
              <Link
                component="button"
                variant="body2"
                onClick={() => setShowForgotPassword(true)}
                style={{
                  cursor: "pointer",
                  textDecoration: "none",
                  color: myTheme.palette.secondary.main,
                }}
              >
                Forgot password?
              </Link>
            </form>
          </Paper>
        </Container>
      )}
    </ThemeProvider>
  );
};

export default Login;
