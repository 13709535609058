// src/components/ForgotPassword.tsx
import React, { useState } from 'react';
import { TextField, Button, Paper, Typography, Container, Avatar, IconButton } from '@mui/material';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';  // Import for back button icon
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { myTheme } from '../../assets/theme';


const ForgotPassword = ({ onBack } : {onBack : Function}) => {  // Added onBack prop for back navigation
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleResetRequest = () => {
        console.log('Reset password request for:', email);
        // Here you would handle the API request to send a reset link to the email
        setMessage('If your email is registered, we have sent instructions to reset your password.');
    };

    return (
        <ThemeProvider theme={myTheme}>
            <Container className='Login-Forget-Content-Container' component="main" maxWidth="xs">
                <Paper elevation={6} style={{ marginTop: '8vh',position:"relative", background:myTheme.palette.background.default, display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                    <IconButton onClick={()=>onBack()} className='ForgotPassword-BackButton'> {/* Back button */}
                        <ArrowBackIcon />
                    </IconButton>
                    <Avatar style={{ margin: '8px', backgroundColor: myTheme.palette.secondary.main }}>
                        <EmailOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Forgot Password
                    </Typography>
                    <Typography component="p" style={{ textAlign: 'center', marginBottom: '20px' }}>
                        Enter your email and we will send instructions to reset your password.
                    </Typography>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <Button
                        type="button"
                        fullWidth
                        variant="contained"
                        color="primary"
                        style={{ margin: '24px 0px 16px',background: myTheme.palette.secondary.main }}
                        onClick={handleResetRequest}
                    >
                        Send Instructions
                    </Button>
                    {message && <Typography style={{ color: 'green', textAlign: 'center' }}>{message}</Typography>}
                </Paper>
            </Container>
        </ThemeProvider>
    );
};

export default ForgotPassword;
