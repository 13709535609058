import { createTheme } from '@mui/material/styles';

export const myTheme = createTheme({
    typography: {
    fontFamily: [
      'Poppins',  // Öncelikli fontunuz
      'sans-serif'  // Yedek font tipi
    ].join(','),
  },
  palette: {
    background: {
      default: '#FDFAE9'  // Light beige background
    },
    primary: {
      main: '#556cd6', 
       // Blue shade as primary for good contrast
    },
    secondary: {
      main: '#63636F',  
    },
    error: {
      main: '#d32f2f',  // Keeps the default error color for clarity
    }
  },
});
