import { IUser } from "./IUser";
import api from "./axios/axios";

export const loginAsync = async (
  username: string,
  password: string
): Promise<IUser | null> => {
  const url = "/login.php";
  const params = new URLSearchParams({
    id: username,
    passwd: password,
  });
  const response = await api.post(url, params);
  return response.data;
};
