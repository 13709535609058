import {
  ActionReducerMapBuilder,
  AsyncThunk,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { UserState } from "../types/userState";
import { loginAsync } from "../../api/Login/Login";
import { UserInfo } from "../../components/Login/ILogin";
import { enqueueSnackbar } from "notistack";
import { IUser } from "../../api/Login/IUser";
import { setError } from "./errorSlice";

const initialState: UserState = {
  user: null,
  isLoading: false,
};

export const login = createAsyncThunk(
  "user/loginUser",
  async (credentials: UserInfo, thunkApi) => {
    const user = await loginAsync(
      credentials.username,
      credentials.password
    ).catch((error) => {
      const errorMessage = {
        ID: 999 /**network hatası */,
        hata: "",
        hataText: error,
      };
      enqueueSnackbar(error);
      thunkApi.dispatch(setError(errorMessage));
      return thunkApi.rejectWithValue(error);
    });
    if ((user as IUser)?.hata > 0) {
      thunkApi.dispatch(setError(user));
      enqueueSnackbar((user as IUser)?.hataText);
      return null;
    }
    enqueueSnackbar("Giriş başarılı");

    return user;
  }
);

const handleAsyncThunk = <Returned, ThunkArg>(
  builder: ActionReducerMapBuilder<UserState>,
  thunk: AsyncThunk<Returned, ThunkArg, object>,
  process?: (
    state: UserState,
    action: ReturnType<typeof thunk.fulfilled>
  ) => void
) => {
  builder.addCase(thunk.pending, (state) => {
    state.isLoading = true;
  });
  builder.addCase(thunk.fulfilled, (state, action) => {
    state.isLoading = false;
    if (process) process(state, action);
  });
  builder.addCase(thunk.rejected, (state, action) => {
    state.isLoading = false;
  });
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    handleAsyncThunk(builder, login, (state, action) => {
      state.user = action.payload as IUser;
    });
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
