// src/App.tsx
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./components/Login/Login";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import "./styles/Login.css";
import { myTheme } from "./assets/theme";
import { Provider } from "react-redux";
import store from "./redux/store/store";
import { SnackbarProvider } from "notistack";
import ErrorHandler from "./components/ErrorHandler/ErrorHandler";

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={myTheme}>
        <SnackbarProvider maxSnack={2} autoHideDuration={1500}>
          <CssBaseline />
          <ErrorHandler />
          <div className="Body-Container">
            <Router>
              <Routes>
                <Route path="/" element={<Login />} />
              </Routes>
            </Router>
          </div>
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
